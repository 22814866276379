.step3 {
    display: grid;
    color: #fff;
    font-family: "Monserrat-bold";
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content min-content;
    max-width: 1000px;
    max-height: 978px;

    // .top {
    //     display: flex;
    //     gap: 2vh;
    //     justify-content: flex-start;
    //     align-items: center;
    //     width: auto;
    //     margin-left: 20px;
    //     // margin-bottom: 4vh;
    //     & > img {
    //         width: 450px;
    //         height: 450px;
    //         &.item-img {
    //             border-radius: 50%;
    //             object-fit: cover;
    //             object-position: center;
    //         }
    //         &:not(.item-img) {
    //             transform: rotate(-30deg);
    //         }
    //     }
    //     div{
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: space-evenly;
    //         max-width: 28vmin;
    //         gap: 2vmin;
    //         h4,h5,h6{
    //             margin: 0;
    //         }
    //         h4,h6{
    //             word-break: break-word;
    //             font-family: Monserrat-bold;
    //             font-size: 3vmin;
    //             text-transform: capitalize;

    //         }
    //         h5{
    //             color: $mainClr;
    //             font-size: 2.5vmin;
    //             font-family: Monserrat;
    //             width: max-content;
    //             &.in-stock{
    //                 color: green;
    //                 text-transform: capitalize;
    //             }
    //             &.out-stock{
    //                 color: $mainClr;
    //                 text-transform: capitalize;
    //             }
    //         }
    //         h6{
    //             font-family: Monserrat;
    //         }
    //     }
    // }

    .top {
        .item-img {
            height: 449px;
            width: 449px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
        }
        .item-img2 {
            position: absolute;
            transform: translatex(-100%);
        }
    }

    .product-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline-start: 51px;
        font-family: "Monserrat", sans-serif;

        &__name {
            font-family: "Monserrat-bold", sans-serif;
            color: white;
            font-size: 23px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            max-height: 106px;
        }
        &__stock {
            text-transform: capitalize;
            font-size: 16px;
            margin-top: 11px;
            &.in-stock {
                color: $greenClr;
            }
            &.out-stock {
                color: $mainClr;
            }
        }
        &__price {
            font-size: 23px;
            font-weight: 600;
            color: white;
            margin-top: 13px;
        }

        &__button {
            width: 100px;
            cursor: pointer;
            font-family: "Monserrat-bold", sans-serif;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 250px;
            border-radius: 60vh;
            height: 50px;
            // & > div {
            //     max-height: -webkit-fill-available;
            // }
        }

        &__size {
            color: white;
            margin-top: 16px;
            cursor: default;
            div {
                width: 100%;
                text-align: left;
            }
            .product-info__user-name {
                font-family: "Monserrat", sans-serif;
                font-size: 17px;
            }
        }
        &__size-picker {
            margin-top: 20px;
            position: relative;
            cursor: pointer;
            color: white;
            z-index: 2;
    
            &>div{
                width: 100%;
                &>div:first-of-type{
                    width: 100%;
                    border-radius: 60vh;
                }
            }
            
            // .MuiInputBase-input {
            //     padding-right: 0 !important;
            //     cursor: pointer;
            // }
            // svg {
            //     color: white !important;
            //     margin-right: 15px;
            // }
        }
        &__add-to-basket {
            background-color: $mainClr;
            margin-top: 23px;
        }
        &__scan {
            cursor: default;
            margin-top: 41px;
            height: min-content;
            img {
                width: 25px;
                margin-right: 7px;
            }
            p {
                margin: 0;
            }
        }

        &__3d-btn {
            margin-top: 23px;
            background-color: #fff;
            color: $mainClr;
        }
        &__user-name {
            font-size: 16px;
            // font-weight: 600;
            color: white;
        }
    }

    .products-container {
        grid-column: span 2;
        margin-top: 15px;
        &__title {
            margin-left: 15px;
            // margin-bottom: 20px;
            font-size: 19px;
            font-weight: bold;
        }
        &__carousel {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // width: 85vw;
            overflow-x: scroll;
            overflow-y: hidden;
            position: relative;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            // height: 100%;

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

            .product-seperator {
                background-color: rgba(202, 202, 202, 0.192);
                width: 2px;
                min-width: 1px;
                height: 70px;
                padding-top: 20px;
            }

            div:first-child .product {
                padding-inline-start: 0;
            }
            div:last-child .product {
                padding-inline-end: 0;
            }
        }
    }

    // h1 {
    //     width: 100%;
    //     // height: 130px;
    //     // margin: 53px 0 43px;
    //     font-family: Monserrat-bold;
    //     font-size: 4.28vmin;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 1.38;
    //     letter-spacing: normal;
    //     text-align: center;
    //     color: #fff;
    //     margin: 0;
    //     span {
    //         text-transform: capitalize;
    //     }
    // }

    // .qr-code {
    //     position: relative;
    //     margin-bottom: 1.4vmin;
    //     img[alt="Barcode Icon"] {
    //         height: 6.25vmin;
    //         margin: 0;
    //     }
    //     span {
    //         width: 100%;
    //         position: absolute;
    //         display: block;
    //         animation-name: beam-laser;
    //         animation-duration: 0.9s;
    //         animation-timing-function: ease-in-out;
    //         animation-iteration-count: infinite;
    //         animation-direction: alternate;
    //         &::before {
    //             content: "";
    //             width: calc(100% + 5px);
    //             border: 2px solid $mainClr;
    //             position: absolute;
    //             top: -8px;
    //             left: -5px;
    //         }
    //     }
    // }

    // h2 {
    //     font-size: 4.28vmin;
    //     margin: 5px 0;
    // }
}

@include media(tablet-portrait) {
    .step3 {
        max-width: 700px;
        .top {
            .item-img {
                width: 308px;
                height: 308px;
            }
        }

        .product-info {
            padding-inline-start: 34px;
            &__name {
                font-size: 18px;
                // -webkit-line-clamp: 1;
            }
            &__stock {
                font-size: 16px;
            }
            &__price {
                margin-top: 12px;
                font-size: 20px;
            }
            &__size {
                margin-top: 13px;
                height: min-content !important;
            }
            &__3d-btn,
            &__size {
                height: 35px;
                min-height: unset;
                font-size: 13px;
            }
            &__size-picker {
                margin-top: 14px;
            }
            &__add-to-basket,
            &__3d-btn {
                margin-top: 20px;
            }
            &__scan {
                font-size: 12px;
                margin-top: 20px;
                height: 15px !important;
            }

            &__button {
                height: 44px;
                font-size: 13px;
            }
        }
        .products-container {
            max-height: 150px;
            margin-top: 53px;

            &__title {
                font-size: 19px;
                margin-bottom: 0;
                padding: 0;
                margin: 0;
                text-align: start;
            }
            &--similar {
            }
            .product {
                height: 143px;
                width: 130px;
                min-height: unset;
                padding: 10px 34px;
                &__name {
                    margin: 3px;
                    font-size: 16px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                &__price {
                    font-size: 16px;
                }
            }
        }
    }
}

@include media(tablet-landscape) {
    .step3 {
        zoom: 0.75;
        max-height: 750px;
        max-width: 976px;
        .top {
            .item-img {
                height: 308px;
                width: 308px;
            }
        }
        .product-info {
            &__name {
                font-size: 18px;
            }
            &__stock {
                font-size: 16px;
            }
            &__price {
                font-size: 20px;
            }
            &__size {
                font-size: 17px;
                margin-top: 10px;
            }
            &__size-picker {
                margin-top: 10px;
            }
            &__add-to-basket,
            &__3d-btn {
                margin-top: 10px;
            }
            &__button {
                height: 44px;
                font-size: 13px;
            }
            &__scan {
                font-size: 12px;
                height: min-content;
            }
        }
        .products-container {
            &__title {
                font-size: 19px;
            }
        }
    }
}

@include media(phone-portrait) {
    .step3 {
        max-width: 90vw;
        grid-template-columns: 100%;
        grid-template-rows: min-content min-content min-content min-content;
        .top {
            justify-self: center;
            .item-img {
                width: 15vh;
                height: 15vh;
            }
        }
        .product-info {
            justify-self: center;
            align-items: center;
            padding: 0;
            margin-bottom: 32px;
            &__name {
                font-size: 15px;
                text-align: center;
            }
            &__stock {
                font-size: 11px;
            }
            &__price {
                font-size: 20px;
                margin-top: 8px;
            }
            &__size {
                font-size: 16px;
                margin-top: 6px;
            }
            &__size-picker {
                margin-top: 14px;
                font-size: 15px;
            }
            &__add-to-basket {
                margin-top: 12px;
                div {
                    font-size: 15px;
                }
            }
            &__button {
                height: 45px;
            }
            &__scan {
                font-size: 12px;
                height: 15px;
                margin-top: 27px;
            }
        }
        .products-container {
            grid-column: 1;
            &__title {
                font-size: 13px;
                text-align: center;
                margin-bottom: 12px;
            }
            &__carousel {
                .product {
                    &__image {
                        img {
                            height: 45px;
                            width: 45px;
                        }
                    }
                    &__name,
                    &__price {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}

@include media(phone-landscape) {
    .step3 {
        grid-template-columns: 50% 50%;
        grid-template-rows: 40% 60%;
        gap: 0;
        .top {
            grid-column: 1;
            grid-row: 1;
            margin: auto;
            .item-img {
                width: 30vh;
                height: 30vh;
            }
        }
        .product-info {
            grid-column: 1;
            grid-row: 2;
            padding: 0;
            justify-self: center;
            align-items: center;
            gap: 2px;
            &__name {
            }
            &__stock {
                margin: 2px 0;
            }
            &__size {
                font-size: 3vmin;
            }
            &__button {
                margin: 0;
            }
            &__size-picker {
                font-size: 3vmin;
                margin-bottom: 5px;
                height: 25px;
            }
            &__add-to-basket {
                min-height: 4vmin;
                font-size: 3vmin;
            }
            &__scan {
                margin-top: 10px;
                & > img {
                    width: 15px;
                }
            }
        }
        .products-container {
            grid-column: 2;
            grid-row: 1;
            width: 100%;
            padding: 0 5px;
            position: relative;
            background-color: #000;
            margin: 0;
            &--similar {
                grid-row: 2;
                margin-top: 10px;
                align-self: center;
            }
            &::before,
            &::after {
                content: "";
                height: 100%;
                width: 0px;
                box-shadow: 0 0 10px 1.5px #fff;
                position: absolute;
                top: 0;
                z-index: -1;
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
    }
}

@include for-big-screen {
    .step3 {
        zoom: 1.35;
    }
}
