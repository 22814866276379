.product {
    font-family: Monserrat;
    text-align: center;
    width: 200px;
    height: 165px;
    // min-height: 190px;
    cursor: pointer;
    padding: 10px 15px;
    color: white;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;

    &__image {
        img {
            border-radius: 50%;
            width: 69px;
            height: 69px;
            object-fit: cover;
            object-position: center;
        }
    }

    &__name {
        margin-top: 10px;
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 3.6em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__price {
        margin-top: 1px;
        font-weight: bold;
    }
}
