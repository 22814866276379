nav {
    // position: absolute;
    // top: 0;
    // left: 0;
    // align-self: flex-start;
    // display: flex;
    // align-items:flex-start;
    // justify-content: space-between;
    width: 100%;
    // padding: 120px 70px 0 120px;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    height: 79px;
    padding: 29px 33px 0 34px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &>img {
        height: 50px;
        width: 50px;
    }

    .cart {
        height: 100%;
        cursor: pointer;
        position: relative;
        height: fit-content;
        &:hover {
            color: aqua;
        }

        &__counter {
            height: 15px;
            width: 15px;
            position: absolute;
            right: -7px;
            background-color: $mainClr;
            border-radius: 100%;
            color: white;
            text-align: center;
            top: 5px;
            font-size: 12px;


        }
        .cart-icon {
            height: 72px;
        }
    }
    button {
        // transform: scale(1.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -4px;
        gap: 1vh;
        width: fit-content;
        padding: 5px 10px;
        height: max-content;
        border: none;
        border-radius: 15px;
        color: #fff;
        background-color: transparent;
        font-family: "Monserrat-semi-bold";
        font-size: 1.2vh;
        div{
            display: flex;
            align-items: center;
            gap: 7.5px;
            margin: 0;
            .redo{
                height: 1.5vh;
            }
        }
        &:hover {
            background-color: $mainClr;
            .redo {
                animation-name: rotate;
                animation-iteration-count: infinite;
                animation-duration: 1s;
                animation-timing-function: linear;
            }
        }
        .qr-restart{
            max-height: 90px;
            height: 5.625vh;
            border-radius: 7.5px;
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@include media(tablet-portrait,tablet-landscape){
    nav{
        &>img{
            height:30px;
            width:30px;
        }
        .cart{
            .cart-icon{
                height:32px;
            }
        }
    }
}
@include media(tablet-landscape){
    nav{
        height: 59px;
    }
}

@include media(phone){
    nav{
        &>img{
            height:20px;
            width:20px;
        }
        .cart{
            .cart-icon{
                height:25px;
            }
        }

    }
}

@include for-big-screen{
    nav{
        zoom: 2;
    }
}