body {
    background-color: black;
    // background: linear-gradient(0deg, rgba(154, 154, 154, 1) 0%, rgba(0, 0, 0, 1) 100%);
    overflow: hidden;

    main {
        // display: grid;
        // grid-template-rows: 1fr;
        // align-items: center;
        // place-content: center;
        width: 100vw;
        height: 100vh;
        padding-top: 79px;
        @include media(tablet-landscape){
            padding-top:59px;
        }
        padding-bottom: 2.5vw;
        display: flex;
        justify-content: center;
        // padding: 100px 100px 100px;
        &>section:not(.loader):not(.error-msg){
            // grid-row: 2;
        }
    }
}

.btn-timer {
    border-radius: 20px;
    width: 70%;
    margin: 0 auto;
    padding: 2px;
    background-color: transparent;
    background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAOqrAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center left;
    -webkit-animation: drawBorderFromCenter calc(var(--duration) * 1ms);
}

/* Chrome, Safari, Opera */
@keyframes drawBorderFromCenter {
    // 99% {
    //     background-size: 0 2px, 0 0, 100% 100%;
    // }
    // 75% {
    //     background-size: 100% 2px, 100% 0, 100% 100%;
    // }
    // 25% {
    //     background-size: 100% 2px, 100% 98%, 100% 100%;
    // }
    // 0% {
    //     background-size: 100% 2px, 100% 98%, 0 2px;
    // }
    0%{
        background-size: 100% 100%;
    }
    95%{
        background-size: 2px 100% ;
    }
    100%{
        background-size: 2px 0;
    }

}

@media (max-width: 600px) {
    body {

        main {
            // padding-top: 60px;
        }
    }
}