html{
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: "Monserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

}

button{
    cursor: pointer;
}

.jBox-Notice.jBox-Notice-blue .jBox-container{
    background-color: #fff;
    color: #000;
    font-size:16px;
    .jBox-content{
        &::before{
            content:url(../img/greenV.svg);
            height: 25px;
            width: 25px;
            margin-inline-end: 12px;
        }
    }
}
.jBox-content{
    text-align: center;
    display: flex;
    justify-content: center;
}

@font-face {
    font-family: "Monserrat";
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
    font-family: "Monserrat-semi-bold";
    src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: "Monserrat-bold";
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
}