@import './style/variables.scss';
@import './style/basic.scss';
@import './style/mixins';
@import './style/views/app';
@import './style//cmps/loaderCmp';
@import './style/cmps/errorMsg';
@import './style/cmps/pieTimer';
@import './style//cmps/nav';
@import './style/cmps/step1';
@import './style/cmps/step2';
@import './style/cmps/step3';
@import './style/cmps/product';
@import './style//cmps/avatar';

.version{
    position: fixed;
    bottom: 0;
    left: 0;
    color: #eee;
    font-size: 12px;
    background-color: transparent;
}