.step1 {
    display: flex;
    justify-content: center;
    height: max-content;

    .img {
        text-align: center;
        img {
            margin-right: 20px;
        }
    }
    .main {
        // flex-grow: 1;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & > img {
            width: 20vmin;
            margin: -2vmin auto 0;
        }
        h1,
        h2 {
            margin: 10px;
        }
        h1 {
            color: #fff;
            font-size: 4vmin;
            line-height: 5vmin;
            font-family: "Monserrat-bold";
        }
        .text {
            margin: 30px 0;
            text-align: center;
            h2 {
                color: #f8686e;
                font-size: 1.2rem;
                line-height: 1.8rem;
                font-family: "Monserrat-semi-bold";
            }
        }
        .animation.footer {
            margin: 0 auto;
            display: flex;
            align-items: flex-start;
            gap: 0.5rem;

            img[alt="Scanner"] {
                width: 8vmin;
                animation-name: scanner;
                animation-duration: 0.9s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
            .qr-code {
                position: relative;
                flex-grow: 1;
                display: flex;
                img[alt="QR Code"] {
                    // width: 50px;
                    width: 5vmin;
                }
                span {
                    width: 100%;
                    position: absolute;
                    display: block;
                    animation-name: beam-laser;
                    animation-duration: 0.9s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                    &::before {
                        content: "";
                        width: calc(100% + 5px);
                        // width: calc(calc(4.5vw + 5px));
                        border: 2px solid $mainClr;
                        position: absolute;
                        top: -5px;
                        left: -5px;
                    }
                }
            }
            div {
                flex-grow: 1;
            }
        }
    }
}

@keyframes scanner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(20deg);
    }
}

@keyframes beam-laser {
    from {
        top: 5px;
    }
    to {
        top: 104%;
    }
}

@media (max-width: 600px) {
    .step1 {
        flex-direction: column;

        .main {
            h1 {
                font-size: 7vmin;
            }
            img {
                width: 40vmin;
            }

            .animation.footer img[alt="Scanner"] {
                width: 15vmin;
            }
            .animation.footer .qr-code img[alt="QR Code"]  {
                width: 9vmin;
            }
        }
        .img {
            img {
                height: 30vh;
                margin-right: 0;
            }
        }
    }
}

@include media(phone-landscape){
    .step1{
        max-height: 70vh;
        align-self: center;
        .img{
            width: 50%;
            display: flex;
            justify-content: flex-end;
            img{
                height: 100%;
                justify-self: flex-end;
            }
        }
        .main{
            width: 50%;
        }
    }
}

@include media(tablet-landscape){
    .step1{
        align-self: center;
    }
}
