.avatar-container {
    background-color: #e3e3e3;
    width: 800px;
    max-height: 650px;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    padding: 65px 20px;
    position: relative;
    overflow: hidden;
    margin: 0 20px;
    margin-top: 35px;

    .close-overlay{
        position: absolute;
        top: 0;
        right: 0;
        width: 10%;
        height: 10%;
        cursor: pointer;
        z-index: 999;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        height: 20px;
        width: 20px;
    }

    .panel {
        display: flex;
        flex-direction: column;
        height: 100%;
        &__size {
            font-size: 30px;
            font-family: Monserrat-bold;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 400px;
            margin-top: 15px;
        }
        select {
            width: 200px;
            background-color: white;
            border-radius: 20px;
            height: 40px;
            text-align: center;
            cursor: pointer;

            outline:none;
            // &:active, &:focus {
            //     outline:none;
            // } 
        }
        .avatar {
            flex-grow: 1;
            iframe{
                border: none;
                height: 100%;
                width: 100%;
                overflow: hidden;
            }
        }
    }
}


@include media(tablet-portrait){}

@include media(tablet-landscape){
    .avatar-container{
        margin-top:10px;
        padding: 40px 0;
        .panel{
            &__size{
                margin-top:0;
            }
        }
    }
}

@include media(phone-portrait){}

@include media(phone-landscape){
    .avatar-container{
        margin-top:0;
        padding: 40px 0;
        .panel{
            &__size{
                margin-top:0;
            }
        }
    }
}

@include for-big-screen{
    .avatar-container{
        max-height: unset;
        width: 80%;
        .panel{
            zoom: 2;
        }
    }
}